.App {
  font-family: sans-serif;
  text-align: center;
}

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Archivo+Black&family=Bebas+Neue&family=Montserrat:wght@400;700&family=Poppins:wght@100;400;700&family=Roboto:wght@400;700&display=swap");

@font-face {
  font-family: "futura";
  src: url("../fonts/Futura.ttc");
  font-weight: normal;
  font-style: normal;
  font-display: fallback;
}

@media only screen and (min-width: 768px) {
  html {
    background: #333;
  }
}

body {
  font-family: "Lato", sans-serif;
  overflow-x: hidden;
  max-width: 500px;
  margin: auto;
  border-radius: 30px;
  padding: 0px 0px;
  margin-top: 30px;
  margin-bottom: 30px;
}

body.bt {
  background-color: #fff;
}

body.wb {
  background-color: #fff;
}

body.ns {
  background-color: #fff;
}

body.bg {
  background-color: #fff;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

li {
  list-style: none;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 25px;
  line-height: 1;
  opacity: 1;
  color: #e60000;
}

.slick-prev:before {
  content: "";
  display: block;
  height: 40px;
  width: 40px;
  background-size: 40px 40px;
  background-image: url("../images/left-arrow.svg");
  background-repeat: no-repeat;
}

.slick-next:before {
  content: "";
  display: block;
  height: 40px;
  width: 40px;
  background-size: 40px 40px;
  background-image: url("../images/right-arrow.svg");
  background-repeat: no-repeat;
}

body.wb .slick-prev:before {
  background-image: url("../images/left-arrow-brown.svg");
}

body.wb .slick-next:before {
  background-image: url("../images/right-arrow-brown.svg");
}

.slick-prev {
  left: 0px;
  z-index: 99;
}

.slick-next {
  right: 25px;
  z-index: 99;
}

.bt-header {
  margin: 20px 0px;
}

.bt-header .bt-store-location {
  height: 60px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.bt-header .bt-store-location img {
  height: 20px;
  margin-right: 5px;
}

.bt-header .bt-search-bar form {
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bt-header .bt-search-bar .form-control {
  display: inline-block;
  width: 65%;
  background-color: #eff0f2;
  border: 1px solid #eff0f2;
}

.bt-header .bt-search-bar .btn {
  display: inline-block;
  width: 32%;
  background-color: #e60000;
  color: #fff;
  border: solid 1px #e60000;
  border-radius: 7px;
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 700;
}

body.wb .bt-header .bt-search-bar .btn {
  background-color: #ce6f19;
  color: #fff;
  border: solid 1px #ce6f19;
}

.bt-header .bt-back img {
  width: 20px;
}

.bt-header .bt-display-search img {
  width: 20px;
}

body .bt-header .bt-logo {
  min-height: 100px;
  background-image: url("../images/logo-header.svg");
  background-position: center center;
  background-size: 50%;
  background-repeat: no-repeat;
}

body.bt .bt-header .bt-logo {
  background-image: url("../images/logo-header.svg");
  background-size: auto 45px;
}

body.wb .bt-header .bt-logo {
  background-image: url("../images/logo-wb.png");
  background-size: auto 80px;
}

body.ns .bt-header .bt-logo {
  background-image: url("../images/logo-ns.jpg");
  background-size: auto 85px;
}

body.bg .bt-header .bt-logo {
  background-image: url("../images/logo-bg.png");
  background-size: auto 80px !important;
}
body.wb .bt-header .bt-logo {
  background-size: 250px auto !important;
}

.bt-filters {
  padding: 20px 0px;
}

.bt-filters .nav {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.bt-filters .nav li {
  width: 45%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bt-filters .nav li a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: solid #eff0f2 1px;
  color: #eff0f2;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bt-filters .nav li a.active {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #e60000;
  border: solid #e60000 1px;
  color: #fff;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.bt-filters .bt-genre-filter {
  display: flex;
  justify-content: space-around;
  padding: 10px 5%;
}

.bt-filters .bt-genre-filter li a.active,
.bt-filters .bt-genre-filter li a:hover,
.bt-filters .bt-genre-filter span.active {
  list-style: none;
  color: #e60000;
  text-decoration: underline;
}

.bt-filters .bt-size-filter {
  margin-top: 15px;
}

.bt-filters .bt-size-filter .bt-product-display-filter .bt-size-list {
  display: block;
  padding: 10px 3%;
}

.bt-filters .bt-size-filter li,
.bt-product-display-filter .bt-size-list li {
  display: inline-block;
  border: 1px solid #000;
  padding: 7px 7px;
  margin-bottom: 3px;
  font-size: 12px;
  width: 34px;
  height: 34px;
  margin: 2px;
}
.bt-filters .bt-size-filter li.active,
.bt-product-display-filter .bt-size-list li.active {
  color: #fff;
  background-color: red;
}

.bt-filters .nav-tabs {
  border-bottom: none;
}

.bt-products-list {
  padding: 0px 20px;
}

.bt-products-list .bt-product {
  min-height: 350px;
  margin-bottom: 20px;
  padding: 20px 0px;
}

.bt-products-list .bt-product .bt-product-img img {
  max-width: 85%;
}

.bt-products-list .bt-product .bt-product-description {
  min-height: 200px;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.bt-products-list .bt-product .bt-product-description h3 {
  font-size: 14px;
  color: #767676;
  text-transform: uppercase;
  font-weight: 700;
}

.bt-products-list
  .bt-product
  .bt-product-description
  .bt-product-description-btn.btn.btn-primary {
  background-color: #e60000;
  border: solid 1px #e60000;
  margin: 20px auto;
  font-weight: 700;
  border-radius: 7px;
}

body.wb
  .bt-products-list
  .bt-product
  .bt-product-description
  .bt-product-description-btn.btn.btn-primary {
  background-color: #ce6f19;
  border: solid 1px #ce6f19;
}

.bt-header-product-display {
  padding-top: 30px;
}

body .bt-header.bt-header-product-display > .row {
  align-items: center;
}

body .bt-header.bt-header-product-display .bt-logo {
  min-height: 85px;
  background-size: contain !important;
  background-repeat: no-repeat;
}

.bt-product-display .bt-product-description {
  min-height: 100px;
  padding: 20px 0px;
}

.bt-product-display .bt-product-description h3 {
  min-height: 35px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  font-size: 14px;
  color: #767676;
  text-transform: uppercase;
  font-weight: 700;
}

.bt-product-description-name {
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  font-size: 16px;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.bt-product-display .bt-product-size-chart-title img {
  width: 20px;
}

.bt-product-display
  .bt-product-size-chart-shipping-available
  .bt-product-size-chart-title
  img {
  width: 25px;
}

.bt-product-display .bt-product-size-chart-title span {
  padding: 0px 0px 0px 5px;
}

.bt-product-display .bt-product-display-filter .bt-size-list {
  padding: 10px 0px 20px 0px;
}

.bt-product-barcode span {
  display: inline-block;
}

.bt-product-barcode span.bt-barcode-top-text {
  font-size: 16px;
}

.bt-product-barcode span.bt-barcode-mid-text {
  font-size: 20px;
  color: #000;
  font-weight: 700;
  margin-bottom: 20px;
}

.bt-product-barcode span.bt-barcode-bottom-text {
  font-size: 14px;
}

.bt-product-display-search {
  height: 80px;
  padding: 20px 15px;
}

.bt-product-display-search .bt-form-wrapper {
  /*display: none;*/
}

.bt-product-display-search .bt-form-wrapper form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

.bt-section-separator {
  background-color: #eff0f2;
  height: 40px;
}

.bt-paginator-container {
  height: 60px;
}

.bt-paginator-container .page-link {
  color: #e60000;
  background-color: #fff;
  border: solid 1px #e60000;
  cursor: pointer;
  border-radius: 0px;
  margin: 0px 5px;
}

.bt-paginator-container .active > .page-link,
.bt-paginator-container.page-link.active {
  color: #fff;
  background-color: #e60000;
  border-color: #e60000;
}

body.wb .bt-paginator-container .page-link {
  color: #ce6f19;
  background-color: #fff;
  border: solid 1px #ce6f19;
  cursor: pointer;
  border-radius: 0px;
  margin: 0px 5px;
}

body.wb .bt-paginator-container .active > .page-link,
body.wb .bt-paginator-container.page-link.active {
  color: #fff;
  background-color: #ce6f19;
  border-color: #ce6f19;
}

.bt-paginator-container li.break {
  margin: 0px 10px;
}

.bt-paginator-container .page-item:first-child .page-link {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.bt-paginator-container .page-item:last-child .page-link {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
.bt-product-img a {
  color: #000 !important;
  background: #fff;
  border: 0;
}
.store-title {
}
.store-title a {
  color: #333;
  font-size: 15px;
  text-decoration: none;
}

.containerLoader {
  margin-top: 40px;
  margin-bottom: 40px;
}
.clockLoader {
  margin-bottom: 60px !important;
}
.clockLoader > span {
  display: block !important;
  margin: 0 auto !important;
}

.clockLoader > span:after {
  content: "Búscando información";
  position: absolute;
  bottom: -30px;
  text-transform: uppercase;
  font-size: 11px;
  width: 200px;
  margin-left: -100px;
}
.customTabs {
  margin-top: 30px;
  margin-bottom: 10px;
}

.customTabs li {
  display: inline-block;
  width: 50%;
  text-align: center;
  border-radius: 13px 13px 0px 0px;
  border: 1px solid #ccc;
  padding: 8px;
}

.customTabs li.react-tabs__tab--selected {
  background: #e60000;
  color: #fff;
}
body.wb .customTabs li.react-tabs__tab--selected {
  background: #ce6f19;
}
.itemCounter {
  /* height: 50px;
  width: 50px; */
}
.digitag-slot {
  text-align: center;
  border: 0px;
  margin: 0 auto;
  display: table;
  border-radius: 4px;
  padding: 4px;
  position: relative;
  margin-bottom: 40px !important;
}

.digitag-slot:after {
  content: " ";
  background-image: url("../images/base_ruleta.svg");
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0;
  width: 268px;
  height: 160px;
  position: absolute;
  left: 60%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -65px;
}

.digitag-slot > span > span {
  border: 7px solid #333;
  margin: 4px;
  border-radius: 5px;
  height: 60px !important;
  width: 60px;
}

/*CONCURSO*/

#bt-main-container {
  padding: 20px 0px;
  position: relative;
}

#bt-main-container.container {
  background: #f9f9f9;
  padding: 20px 35px;
  border-radius: 0px 0px 30px 30px;
}

#bt-main-container.container .bt-text h1 {
  font-size: 28px;
  font-weight: 600;
  margin-top: 40px;
  text-transform: uppercase;
}

body.bt #bt-main-container.container .bt-text h1 {
  color: #ff0000;
  font-family: "Bebas Neue", sans-serif;
}

body.bg #bt-main-container.container .bt-text h1 {
  font-size: 36px;
}

body.bg #bt-main-container.container .bt-text h1 .bt-color-1 {
  color: #6a4ec9;
}

body.bg #bt-main-container.container .bt-text h1 .bt-color-2 {
  color: #e44756;
}

body.wb #bt-main-container.container .bt-text h1 {
  font-size: 36px;
}

body.ns #bt-main-container.container .bt-text h1 {
  font-size: 36px;
  font-family: "Archivo Black", sans-serif;
}

#bt-main-container.container .bt-text h2 {
  font-size: 16px;
  font-weight: 600;
}

body.bt #bt-main-container.container .bt-text h2 {
  font-size: 16px;
  font-family: "Bebas Neue", sans-serif;
}

body.bg #bt-main-container.container .bt-text h2 {
  font-size: 16px;
  font-weight: 600;
}

body.wb #bt-main-container.container .bt-text h2 {
  font-size: 16px;
  font-weight: 600;
}

body.ns #bt-main-container.container .bt-text h2 {
  font-size: 16px;
  font-weight: 400;
}

#bt-main-container .bt-slot-machine {
  min-height: 200px;
  /* background-image: url("../images/ruleta-bata.svg"); */
  background-position: center center;
  background-size: 60%;
  background-repeat: no-repeat;
  margin-top: 40px;
}

body.bt #bt-main-container .bt-slot-machine {
  /* background-image: url("../images/ruleta-bata.svg"); */
}

body.bg #bt-main-container .bt-slot-machine {
  /* background-image: url("../images/ruleta-bg.png");
  background-size: 65%; */
}

body.wb #bt-main-container .bt-slot-machine {
  /* background-image: url("../images/ruleta-wb.svg");
  background-size: 70%; */
}

body.ns #bt-main-container .bt-slot-machine {
  /* background-image: url("../images/ruleta-ns.svg");
  background-size: contain; */
}

#bt-main-container.container .bt-form,
#bt-main-container.container .bt-result-message {
  margin: 20px 0px 0px 0px;
}

#bt-main-container .form-group .form-control {
  display: block;

  height: calc(1.5em + 0.75rem + 2px);
  padding: 20px 10px;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #000;
  border-radius: 10px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  margin: auto;
}

body.bt #bt-main-container .form-group .form-control {
}

body.bg #bt-main-container .form-group .form-control {
}

body.wb #bt-main-container .form-group .form-control {
}

body.ns #bt-main-container .form-group .form-control {
}

#bt-main-container .form-group label {
  display: inline-block;
  margin-bottom: 0.5rem;
  text-align: center;
  width: 100%;
  font-size: 12px;
  font-weight: 700;
}

#bt-main-container .checkbox {
  text-align: center;
  font-size: 12px;
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}

#bt-main-container .checkbox input[type="checkbox"] {
  box-sizing: border-box;
  width: 20px;
  height: 20px;
  position: relative;
  top: 5px;
  left: -5px;
}

#bt-main-container a.bt-btn,
#bt-main-container .bt-btn {
  width: 100%;
  background-color: #e60000;
  border: solid 1px #e60000;
  margin: 20px auto;
  font-weight: 400;
  border-radius: 7px;
  color: #fff;
  text-transform: uppercase;
  padding: 20px;
  display: block;
}
.btn-play-again {
  background-color: #000 !important;
  color: #fff !important;
}
#bt-main-container .btn-play::after {
  /* content: "sorpréndeme"; */
  text-align: center;
  text-transform: uppercase;
}

body.bt #bt-main-container a.bt-btn,
body.bt #bt-main-container .bt-btn {
  background-color: #e60000;
  border: solid 1px #e60000;
  margin: 20px auto;
  font-weight: 400;
  border-radius: 0px;
  color: #fff;
  text-transform: uppercase;
  padding: 20px;
  display: block;
  font-family: "Montserrat", sans-serif;
}

body.bt #bt-main-container .btn-play::after {
  /* content: "sorpréndeme"; */
  text-align: center;
  text-transform: uppercase;
}

body.bg #bt-main-container a.bt-btn,
body.bg #bt-main-container .bt-btn {
  /* width: 85%; */
  /* background-color: transparent; */
  /* background-image: url(../images/btn_ruleta-bg.svg); */
  background-repeat: no-repeat;
  background-size: contain;
  /* border: none;
  margin: 20px auto;
  font-weight: 400;
  border-radius: 7px;
  color: transparent;
  text-transform: uppercase; */
  /* padding: 24px; */
  display: block;
}

body.bg #bt-main-container .bt-btn.btn-play-again {
  /* background-image: url(../images/btn-nuevamente_ruleta-bg.svg); */
}

body.bg #bt-main-container .btn-play::after {
  /* content: "¡quiero ganar!"; */
  text-align: center;
  text-transform: uppercase;
}

body.wb #bt-main-container a.bt-btn,
body.wb #bt-main-container .bt-btn {
  width: 70%;
  background-color: #8fba84;
  border: solid 1px #8fba84;
  margin: 20px auto;
  font-weight: 400;
  border-radius: 45px;
  color: #000;
  text-transform: uppercase;
  padding: 10px;
  display: block;
  font-family: "futura";
}

body.wb #bt-main-container .btn-play::after {
  /* content: "quiero ganar!"; */
  text-align: center;
  text-transform: uppercase;
}

body.ns #bt-main-container a.bt-btn,
body.ns #bt-main-container .bt-btn {
  width: 70%;
  background-color: #000000;
  border: solid 1px #000000;
  margin: 20px auto;
  font-weight: 400;
  border-radius: 45px;
  color: #fff;
  text-transform: uppercase;
  padding: 10px;
  display: block;
  font-family: "Archivo Black", sans-serif;
}

body.wb #bt-main-container .btn-play::after {
  /* content: "quiero ganar!"; */
  text-align: center;
  text-transform: uppercase;
}

#bt-main-container .bt-promo-code-display {
  text-align: center;
}

#bt-main-container .bt-promo-title {
  font-size: 16px;
  font-weight: 400;
  display: block;
}

#bt-main-container .bt-promo-code {
  font-size: 46px;
  font-weight: 700;
  display: block;
}
.selectStore {
  border: 2px solid;
  padding: 0;
  padding-top: 1px;
  padding-bottom: 1px;
  border-radius: 6px;
  color: #333;
}
.containerLoader {
  position: absolute;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  text-align: center;
  margin-top: -30px;
  background: rgb(255 255 255 / 80%);
  z-index: 100;
}
.containerLoader > span {
  display: inherit;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -26px;
}

.ns.single-discount {
}
.ns.single-discount #bt-main-container.container {
  background: #000;
  color: #fff;
}
.ns.single-discount #bt-main-container a.bt-btn,
.ns.single-discount #bt-main-container .bt-btn {
  border: 1px solid #bdfc39;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: normal !important;
}
.ns.single-discount #bt-main-container a.bt-btn,
.ns.single-discount #bt-main-container .bt-btn {
}
.ns.single-discount #bt-main-container .form-group .form-control {
  border-radius: 0px;
}
.ns.single-discount #bt-main-container .form-group label {
  text-align: left;
}
body.single-discount .img-20percent {
  padding: 30px;
}
body.single-discount #bt-main-container.container .bt-text h2 {
  font-size: 23px;
}

.wb.single-discount #bt-main-container.container {
  background: #4cf381;
  color: #000;
}
body.single-discount .infoRequired {
  font-size: 17px !important;
}

.bt.single-discount #bt-main-container.container .bt-text h1 {
  color: #000;
}

.bg.single-discount #bt-main-container.container {
  background: #fff093 !important;
}
.bg.single-discount #bt-main-container a.bt-btn,
.bg.single-discount #bt-main-container .bt-btn {
  background: #db1b91;
  border-color: #db1b91;
  color: #fff !important;
  border-radius: 30px;
}
.wb.single-discount #bt-main-container a.bt-btn,
.wb.single-discount #bt-main-container .bt-btn {
  background: #000;
  border-color: #000;
  color: #fff;
}
